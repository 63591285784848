import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useRef, useState } from 'react';

import BackIcon from '../../../../../static/images/icon-back.inline.svg';

import TowerDefenseButton from '../TowerDefenseButton';
import FightForGloryAttacker from './FightForGloryAttacker';
import FightForGloryDefender from './FightForGloryDefender';
import GiveOrders from './GiveOrders';
import SetupCrypts from './SetupCrypts';
import SetupTowers from './SetupTowers';
import SubmitTransactionAttacker from './SubmitTransactionAttacker';
import SubmitTransactionDefender from './SubmitTransactionDefender';

type Faction = 'defender' | 'attacker';

const defenderData = [
  {
    title: 'Defend the peace of the Jungle',
    description:
      "In tower defense games, the game goal as defender is to strategically place defensive structures to prevent enemy attackers from reaching the player's base. It requires strategic planning, resource management, and quick thinking to succeed.",
    contentComponent: <SetupTowers />
  },
  {
    title: 'Fight for Jungle Glory',
    description:
      "In tower defense games, the game goal as defender is to strategically place defensive structures to prevent enemy attackers from reaching the player's base. It requires strategic planning, resource management, and quick thinking to succeed.",
    contentComponent: <FightForGloryDefender />
  },
  {
    title: 'Experience On-chain PvP Gaming',
    description:
      "In tower defense games, the game goal as defender is to strategically place defensive structures to prevent enemy attackers from reaching the player's base. It requires strategic planning, resource management, and quick thinking to succeed.",
    contentComponent: <SubmitTransactionDefender />
  }
];

const attackerData = [
  {
    title: 'Architect victory with the undead',
    description:
      "In tower defense games, the goal of the attacker is to overcome the defender's towers and reach their base. This requires strategic thinking, as attackers must choose the right types of units and coordinate their attacks to bypass the defender's defenses.",
    contentComponent: <SetupCrypts />
  },
  {
    title: 'Fight for Jungle Glory',
    description:
      "In tower defense games, the goal of the attacker is to overcome the defender's towers and reach their base. This requires strategic thinking, as attackers must choose the right types of units and coordinate their attacks to bypass the defender's defenses.",
    contentComponent: <FightForGloryAttacker />
  },
  {
    title: 'Let the undead do your bidding',
    description:
      "In tower defense games, the goal of the attacker is to overcome the defender's towers and reach their base. This requires strategic thinking, as attackers must choose the right types of units and coordinate their attacks to bypass the defender's defenses.",
    contentComponent: <GiveOrders />
  },
  {
    title: 'Experience On-chain PvP Gaming',
    description:
      "In tower defense games, the goal of the attacker is to overcome the defender's towers and reach their base. This requires strategic thinking, as attackers must choose the right types of units and coordinate their attacks to bypass the defender's defenses.",
    contentComponent: <SubmitTransactionAttacker />
  }
];

const IntroSection = ({ onFactionSelect }: { onFactionSelect: (faction: Faction) => void }) => {
  return (
    <div className="gap-24 md:gap-40 lg:gap-56 flex flex-col md:flex-row items-center md:items-stretch">
      <div className="p-24 pt-32 max-w-[430px] td-glass-bg rounded-[32px] flex flex-col gap-16 items-center justify-center flex-1">
        <h3 className="text-32 font-bold text-white text-center">
          <Trans>Defender</Trans>
        </h3>
        <img src="/images/td-faction-defender.webp" alt="" />
        <TowerDefenseButton onClick={() => onFactionSelect('defender')}>
          <Trans>Click here to learn more</Trans>
        </TowerDefenseButton>
      </div>
      <div className="w-[120px] flex flex-col items-center justify-center">
        <img src="/images/versus.png" alt="versus" />
      </div>
      <div className="p-32 pt-40 max-w-[430px] td-glass-bg rounded-[32px] flex flex-col gap-16 items-center justify-center flex-1">
        <h3 className="text-32 font-bold text-white text-center">
          <Trans>Attacker</Trans>
        </h3>
        <img src="/images/td-faction-attacker.webp" alt="" />
        <TowerDefenseButton onClick={() => onFactionSelect('attacker')}>
          <Trans>Click here to learn more</Trans>
        </TowerDefenseButton>
      </div>
    </div>
  );
};

const availableWrapperBackgroundClassnames = [
  "bg-[url('/images/td-faction-defender-background.webp')]",
  "bg-[url('/images/td-faction-attacker-background.webp')]",
  "bg-[url('/images/td-faction-background.webp')]"
];

const getWrapperBackgroundClassname = (faction: Faction | null) => {
  if (faction === 'defender') {
    return availableWrapperBackgroundClassnames[0];
  }

  if (faction === 'attacker') {
    return availableWrapperBackgroundClassnames[1];
  }

  return availableWrapperBackgroundClassnames[2];
};

const ChooseFaction = () => {
  const [page, setPage] = useState(0);
  const [faction, setFaction] = useState<Faction | null>('defender');

  const factionHolderRef = useRef<HTMLDivElement>(null);

  const sourceData = faction === 'attacker' ? attackerData : defenderData;

  function handlePreviousPageMove(currentPage: number) {
    if (currentPage > 0) {
      setPage(currentPage - 1);
    }
  }

  function handleNextPageMove(currentPage: number, maxPages: number) {
    if (currentPage === maxPages - 1) {
      return;
    }

    setPage(currentPage + 1);
  }

  return (
    <div
      className={classNames(
        getWrapperBackgroundClassname(faction),
        'bg-center bg-cover bg-no-repeat',
        'relative',
        'lg:min-h-[800px]'
      )}
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 w-full h-full td-bg-gradient" />
      <div className="container-slim pt-72 pb-72 relative z-20">
        <div ref={factionHolderRef}>
          {/* {faction === null && (
            <div>
              <h2 className="text-32 xs:text-40 text-white font-bold">
                <Trans>Choose Your Faction</Trans>
              </h2>
              <p className="mt-16 text-white">
                <Trans>
                  Will you fight with the animals of the jungle to defend their homes, or join the undead and summon
                  crypts to overpower them?
                </Trans>
              </p>
              <div className="mt-48">
                <IntroSection
                  onFactionSelect={(faction) => {
                    setFaction(faction);
                    if (typeof window !== 'undefined') {
                      if (!factionHolderRef || !factionHolderRef.current) {
                        return;
                      }

                      const topPosition = factionHolderRef.current.getBoundingClientRect()?.top + window.scrollY;

                      window.scrollTo({
                        top: topPosition - (window.innerWidth <= 1000 ? 100 : 220),
                        behavior: 'smooth'
                      });
                    }
                  }}
                />
              </div>
            </div>
          )} */}
          <div className="max-w-[700px] mx-auto mb-40 lg:mb-80">
            <div className="flex flex-col xs:flex-row gap-16 xs:gap-4 xs:items-center justify-between">
              <TowerDefenseButton
                onClick={() => {
                  setPage(0);
                  setFaction('defender');
                }}
                outlineVariant={faction !== 'defender'}
                customClassName="xs:min-w-[250px]"
              >
                <Trans>Defender</Trans>
              </TowerDefenseButton>
              <div className="max-w-[40px] mx-auto flex flex-col items-center justify-center">
                <img src="/images/versus.png" alt="versus" />
              </div>
              <TowerDefenseButton
                onClick={() => {
                  setPage(0);
                  setFaction('attacker');
                }}
                outlineVariant={faction !== 'attacker'}
                customClassName="xs:min-w-[250px]"
              >
                <Trans>Attacker</Trans>
              </TowerDefenseButton>
            </div>
          </div>
          {faction !== null && (
            <div>
              {sourceData.map((section, index) => {
                if (index !== page) {
                  return null;
                }

                return (
                  <div key={`${faction}${index}`}>
                    <div>
                      <div className="flex flex-col-reverse lg:flex-row lg:items-center lg:justify-between gap-24">
                        <h2 className="text-32 text-white font-bold">
                          <Trans>{section.title}</Trans>
                        </h2>
                        <div className="flex items-center gap-16 md:gap-24 self-end">
                          <div className="flex items-center gap-8">
                            {sourceData.map((_, index) => (
                              <div
                                key={index}
                                className={classNames(
                                  index === page ? 'bg-white' : 'bg-white/50',
                                  'rounded-full',
                                  'w-[8px] h-[8px]',
                                  'cursor-pointer'
                                )}
                                onClick={() => setPage(index)}
                              />
                            ))}
                          </div>
                          <div className="flex gap-8 md:gap-16">
                            <TowerDefenseButton
                              outlineVariant
                              smallVariant
                              onClick={() => handlePreviousPageMove(page)}
                              customClassName="min-h-[48px]"
                              iconComponent={
                                <span className="fill-white">
                                  <BackIcon />
                                </span>
                              }
                              iconSpacingClassname="gap-2"
                              iconComponentClassname="hidden sm:flex"
                            >
                              <Trans>Back</Trans>
                            </TowerDefenseButton>
                            {sourceData.length - 1 === page ? (
                              <TowerDefenseButton
                                smallVariant
                                customClassName="min-h-[48px]"
                                iconComponent={
                                  <span className="fill-white rotate-180">
                                    <BackIcon />
                                  </span>
                                }
                                iconComponentPlacement="last"
                                iconSpacingClassname="gap-2"
                                iconComponentClassname="hidden sm:flex"
                                renderAsLink
                                to="https://tower-defense.paimastudios.com"
                                target="_blank"
                              >
                                <Trans>Play now</Trans>
                              </TowerDefenseButton>
                            ) : (
                              <TowerDefenseButton
                                smallVariant
                                onClick={() => handleNextPageMove(page, sourceData.length)}
                                iconComponent={
                                  <span className="fill-white rotate-180">
                                    <BackIcon />
                                  </span>
                                }
                                iconComponentPlacement="last"
                                iconSpacingClassname="gap-2"
                                iconComponentClassname="hidden sm:flex"
                              >
                                <Trans>Next</Trans>
                              </TowerDefenseButton>
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="mt-8 text-white max-w-[820px]">
                        <Trans>{section.description}</Trans>
                      </p>
                    </div>
                    <div className="mt-24">{section.contentComponent}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {/*
        <div className="mt-48 md:mt-120 lg:mt-200">
          <Link
            to="#"
            className="text-center w-full text-white font-18 uppercase flex flex-col items-center gap-16"
            onClick={(e) => {
              e.preventDefault();
              scrollTo('#leaderboard');
            }}
          >
            <Trans>Game leaderboard</Trans>
            <div className="fill-white">
              <ArrowIcon />
            </div>
          </Link>
        </div>
        */}
      </div>
      {availableWrapperBackgroundClassnames.map((bg) => (
        <img key={bg} src={bg} alt="" loading="lazy" className="hidden" />
      ))}
    </div>
  );
};

export default ChooseFaction;
