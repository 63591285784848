import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { formatNumber, getLeaderboardPosition } from '../../../services/utils';

import Tooltip from '../../Tooltip';
import CopyableAddress from '../CopyableAddress';
import CornerNFTBadge from './CornerNFTBadge';
import { LeaderboardEntryProps } from './LeaderboardEntry';

interface LeaderboardCardProps extends LeaderboardEntryProps {
  largeVariant?: boolean;
}

const ColumnTitle = ({ text }: { text: string }) => {
  return <p className="uppercase text-[10px] font-bold text-center">{text}</p>;
};

const ColumnValue = ({ text, extraTopPadding }: { text?: string; extraTopPadding?: boolean }) => {
  return (
    <p
      className={classNames(
        'uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold text-center',
        extraTopPadding && 'pt-4'
      )}
    >
      {text}
    </p>
  );
};

const weeklyRewardsTooltips: Record<number, JSX.Element> = {
  1: <Trans>$100 worth of Tarochi Gold</Trans>,
  2: <Trans>$70 worth of Tarochi Gold</Trans>,
  3: <Trans>$40 worth of Tarochi Gold</Trans>
};

const streakRewardsTooltips: Record<number, JSX.Element> = {
  1: <Trans>$100 worth of Tarochi Gold</Trans>,
  2: <Trans>$50 worth of Tarochi Gold</Trans>,
  3: <Trans>$20 worth of Tarochi Gold</Trans>
};

const getTarochiBadgeTooltipText = (position: number | string, streakTooltips?: boolean) => {
  const numPosition = typeof position === 'number' ? position : parseInt(position);

  if (numPosition < 4 && numPosition > 0) {
    return streakTooltips ? streakRewardsTooltips[numPosition] : weeklyRewardsTooltips[numPosition];
  }

  return '';
};

const LeaderboardCard = ({
  wallet_address,
  wallet_alias,
  avatar_url,
  wins,
  draws,
  losses,
  position,
  score,
  name,
  current_streak,
  longest_streak,
  largeVariant = false,
  wrapperClassname,
  showCornerNFTBadge,
  showRewardsBadge,
  showStreakData
}: LeaderboardCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'relative',
        'flex-1',
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'rounded-[24px]',
        'px-[8px] lg:px-[16px]',
        'text-white',
        largeVariant ? 'py-[32px] pt-[56px]' : 'py-[32px]',
        largeVariant ? 'bg-[#DCA0334D]/30' : 'bg-[#B2BBC7]/20',
        wrapperClassname
      )}
    >
      {showCornerNFTBadge && (
        <div className="absolute top-0 right-0">
          <CornerNFTBadge />
        </div>
      )}
      <div className="absolute w-[60px] h-[60px] -top-[30px] left-[16px]">
        {getLeaderboardPosition(position) === 1 && <img src="/images/medal-first.webp" alt="First place medal" />}
        {getLeaderboardPosition(position) === 2 && <img src="/images/medal-second.webp" alt="Second place medal" />}
        {getLeaderboardPosition(position) === 3 && <img src="/images/medal-third.webp" alt="Third place medal" />}
      </div>
      <div
        className={classNames(
          largeVariant ? 'w-[110x] min-w-[110px] h-[114px]' : 'w-[80px] min-w-[80px] h-[84px]',
          'rounded-[16px]',
          'mb-[16px] lg:mb-[24px]'
        )}
      >
        <img
          src={avatar_url ?? ''}
          alt=""
          className={classNames(largeVariant ? 'w-[110px] h-[110px]' : 'w-[80px] h-[80px]', 'rounded-[16px]')}
        />
      </div>
      <div
        className={classNames(
          'flex',
          'flex-col',
          'justify-center',
          'items-center',
          largeVariant ? 'mb-[40px]' : 'mb-[16px]'
        )}
      >
        <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">{name}</p>
        {wallet_address !== '' && (
          <CopyableAddress
            address={wallet_address}
            alias={wallet_alias}
            successMessageClasses="hidden md:inline text-white"
            textClasses="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-normal text-white"
          />
        )}
      </div>
      <div className={classNames('flex flex-wrap mt-12 sm:gap-x-8 lg:gap-x-12 sm:gap-y-12 w-full justify-center')}>
        {showStreakData ? (
          <>
            <div>
              <ColumnTitle text={t('Longest streak')} />
              <ColumnValue
                text={longest_streak !== undefined ? formatNumber(longest_streak) : undefined}
                extraTopPadding={showRewardsBadge}
              />
            </div>
            <div>
              <ColumnTitle text={t('Current streak')} />
              <ColumnValue
                text={current_streak !== undefined ? formatNumber(current_streak) : undefined}
                extraTopPadding={showRewardsBadge}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <ColumnTitle text={t('Wins')} />
              <ColumnValue
                text={wins !== undefined ? formatNumber(wins) : undefined}
                extraTopPadding={showRewardsBadge}
              />
            </div>
            <div>
              <ColumnTitle text={t('Draws')} />
              <ColumnValue
                text={draws !== undefined && draws !== null ? formatNumber(draws) : undefined}
                extraTopPadding={showRewardsBadge}
              />
            </div>
            <div>
              <ColumnTitle text={t('Losses')} />
              <ColumnValue
                text={losses !== undefined ? formatNumber(losses) : undefined}
                extraTopPadding={showRewardsBadge}
              />
            </div>
            <div>
              <ColumnTitle text={t('Points')} />
              <ColumnValue
                text={score !== undefined ? formatNumber(score) : undefined}
                extraTopPadding={showRewardsBadge}
              />
            </div>
          </>
        )}
        {showRewardsBadge && (
          <Tooltip text={getTarochiBadgeTooltipText(position, showStreakData)} sizeVariant="small">
            <ColumnTitle text={t('Rewards')} />
            <div className="flex items-center justify-center pt-2">
              <img className="w-[28px] h-[28px]" src="/images/icon-tarochi-gold.webp" />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default LeaderboardCard;
