import { Trans } from 'gatsby-plugin-react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useState } from 'react';

import TowerDefenseButton from './TowerDefenseButton';

import classNames from 'classnames';
import ArrowIcon from '../../../../static/images/icon-arrow.inline.svg';
import CodeLaptopLogo from '../../../../static/images/icon-code-laptop.inline.svg';
import JoystickIcon from '../../../../static/images/icon-joystick.inline.svg';

const Hero = (props: { next: string }) => {
  const [firstVideoLoaded, setFirstVideoLoaded] = useState(false);

  return (
    <div className="relative min-h-[500px] md:min-h-[50svh] flex flex-col justify-center">
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-black">
        <div className="relative w-full bg-switch-video h-full min-h-full object-cover">
          <video
            onTimeUpdate={() => {
              if (!firstVideoLoaded) {
                setTimeout(() => {
                  setFirstVideoLoaded(true);
                }, 400);
              }
            }}
            className={classNames(
              firstVideoLoaded ? 'opacity-30' : 'opacity-0',
              'w-full',
              'h-full',
              'object-cover',
              'absolute',
              'top-0',
              'bottom-0',
              'left-0',
              'right-0',
              'z-0'
            )}
            playsInline
            autoPlay
            muted
            loop
          >
            <source className="object-cover" src="/video/td-hero-video-2.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-0 bottom-0 w-full h-full td-bg-gradient td-bg-gradient--light z-10" />
      <div className="pt-32 pb-40 md:pt-64 relative z-20">
        <div className="container-slim lg:flex lg:gap-80">
          <div className="flex-1">
            <h1
              className="text-32 md:text-32 lg:text-48 font-bold text-white md:max-w-[700px]"
              style={{ textTransform: 'capitalize' }}
            >
              <Trans>Experience intense, thoughtful Tower Defense battles against other players</Trans>
            </h1>
            <div className="flex items-center gap-8 mt-16">
              <div className="w-[40px] flex items-center justify-center">
                <img src="/images/chain-xai.png" />
              </div>
              <a href="https://xai.games/" className="text-white text-18 hover:underline" target="_blank">
                <Trans>Now live on Xai</Trans>
              </a>
            </div>
            <div className="mt-24 flex flex-col gap-16 md:flex-row md:max-w-[550px]">
              <TowerDefenseButton
                iconComponent={<JoystickIcon />}
                customClassName="w-full md:min-w-[275px]"
                renderAsLink
                to="https://tower-defense.paimastudios.com/"
                target="_blank"
              >
                <Trans>Play Now</Trans>
              </TowerDefenseButton>
              <TowerDefenseButton
                outlineVariant
                iconComponent={<CodeLaptopLogo />}
                customClassName="w-full"
                onClick={() => {
                  scrollTo(props.next);
                }}
              >
                <Trans>Build now</Trans>
              </TowerDefenseButton>
            </div>
          </div>
          <div className="hidden lg:block">
            <div className="w-[280px]">
              <img src="/images/td-logo.svg" alt="Tower Defense Logo" />
            </div>
          </div>
        </div>
        <div className="mt-48 md:mt-120 lg:mt-200">
          <button
            type="button"
            className="text-center w-full text-white font-18 uppercase flex flex-col items-center gap-16"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(props.next);
            }}
          >
            <Trans>Scroll down to learn more</Trans>
            <div className="fill-white">
              <ArrowIcon />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
