import { Link } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { ReactElement } from 'react';

import TowerDefenseButton from './TowerDefenseButton';

import ArrowIcon from '../../../../static/images/icon-arrow.inline.svg';
import ChainIcon from '../../../../static/images/icon-chain-record.inline.svg';
import JoystickIcon from '../../../../static/images/icon-joystick.inline.svg';
import NFTIcon from '../../../../static/images/icon-nft.inline.svg';
import PodiumIcon from '../../../../static/images/icon-podium.inline.svg';

const PoweredByItem = ({ title, icon }: { title: string; icon: ReactElement }) => {
  return (
    <article className="td-glass-bg p-24 rounded-[32px] flex items-center justify-center gap-16 flex-col">
      <h4 className="text-white font-bold text-20 text-center">{title}</h4>
      <div className="w-[56px] h-[56px] flex items-center justify-center fill-tower-defense-yellow">{icon}</div>
    </article>
  );
};

const PoweredBy = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-[url('/images/td-powered-by-background.webp')] bg-center bg-cover bg-no-repeat relative">
      <div className="absolute left-0 right-0 top-0 bottom-0 w-full h-full td-bg-gradient" />
      <div className="container-slim pt-72 pb-40 relative z-20">
        <div className="">
          <div className="flex flex-col md:flex-row gap-24">
            <div className="flex-1">
              <h2 className="text-32 xs:text-40 text-white font-bold">
                <Trans>Wrath of the Jungle: Tower Defense</Trans>
              </h2>
              <p className="mt-16 text-white">
                <Trans>
                  Wrath of the Jungle: Tower Defense is a fast-paced game where players choose to play as either
                  attackers or defenders. As an attacker, players lead hordes of enemy creatures through the jungle to
                  destroy the defender's base, while defenders use a variety of towers to fend off attackers.
                </Trans>
              </p>
              <p className="mt-16 text-white">
                <Trans>
                  With several maps to choose from, players can upgrade their towers and gain new abilities to maximize
                  their defensive capabilities. The game offers a thrilling gaming experience for both aggresive
                  attackers and valiant defenders.
                </Trans>
              </p>
              <div className="mt-32">
                <TowerDefenseButton
                  iconComponent={<JoystickIcon />}
                  renderAsLink
                  to="https://tower-defense.paimastudios.com"
                  target="_blank"
                >
                  <Trans>Play now</Trans>
                </TowerDefenseButton>
              </div>
            </div>
            <div className="w-full max-w-[440px] flex-1">
              <img src="/images/td-paima-engine-powered-characters.webp" alt="" />
            </div>
          </div>
          <div className="mt-24 md:mt-48">
            <h3 className="text-24 text-white font-bold">
              <Trans>Powered by Paima Engine</Trans>
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-24 mt-32">
              <PoweredByItem title={t('NFT 2.0 - Stateful NFTs')} icon={<NFTIcon />} />
              <PoweredByItem title={t('Game Leaderboards')} icon={<PodiumIcon />} />
              <PoweredByItem title={t('On-Chain Record')} icon={<ChainIcon />} />
            </div>
          </div>
        </div>
        <div className="mt-48">
          <Link
            to="#"
            className="text-center w-full text-white font-18 uppercase flex flex-col items-center gap-16"
            onClick={(e) => {
              e.preventDefault();
              scrollTo('#mechanics');
            }}
          >
            <Trans>Learn the mechanics</Trans>
            <div className="fill-white">
              <ArrowIcon />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PoweredBy;
