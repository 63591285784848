import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { LeaderboardEntryType } from '../../../services/types';
import { formatNumber, getLeaderboardPosition } from '../../../services/utils';

import Tooltip from '../../Tooltip';
import CopyableAddress from '../CopyableAddress';
import CornerNFTBadge from './CornerNFTBadge';

export interface LeaderboardEntryProps extends LeaderboardEntryType {
  position: number | string;
  avatar_url?: string;
  name?: string;
  wallet_address: string;
  wallet_alias?: string;
  wrapperClassname?: string;
  showCornerNFTBadge?: boolean;
  showRewardsBadge?: boolean;
  showStreakData?: boolean;
}

const getTarochiBadgeTooltipText = (position: number | string) => {
  const numPosition = typeof position === 'number' ? position : parseInt(position);

  if (numPosition >= 4 && numPosition <= 20) {
    if (numPosition >= 10) {
      return <Trans>$10 worth of Tarochi Gold</Trans>;
    }
    if (numPosition >= 4) {
      return <Trans>$20 worth of Tarochi Gold</Trans>;
    }

    return '';
  }

  return '';
};

const LeaderboardEntry = ({
  wallet_address,
  wallet_alias,
  avatar_url,
  wins,
  draws,
  losses,
  position,
  score,
  current_streak,
  longest_streak,
  name,
  wrapperClassname,
  showCornerNFTBadge,
  showRewardsBadge,
  showStreakData
}: LeaderboardEntryProps) => {
  const { t } = useTranslation();

  const hasPodiumPosition =
    getLeaderboardPosition(position) === 1 ||
    getLeaderboardPosition(position) === 2 ||
    getLeaderboardPosition(position) === 3;

  const hasVisibleCornerBadge = showCornerNFTBadge && hasPodiumPosition;

  const gridCols = showStreakData ? 2 : 3 + (showRewardsBadge ? 1 : 0) + (draws !== null ? 1 : 0);

  return (
    <li
      className={classNames(
        'relative',
        'px-12',
        'py-16',
        'td-glass-bg',
        'rounded-[12px]',
        'text-white',
        wrapperClassname
      )}
    >
      {hasVisibleCornerBadge && (
        <div className="xs:hidden absolute top-0 right-0 z-10">
          <CornerNFTBadge size="small" />
        </div>
      )}
      <div className={classNames('xs:flex xs:justify-between xs:items-center')}>
        <div className="flex items-center">
          <div className="md:w-[60px]">
            <div className="w-[32px] h-[32px] md:w-[40px] md:h-[40px] mr-8 sm:mr-12 md:mr-24 flex items-center md:block">
              {hasPodiumPosition ? (
                <>
                  <div className="md:hidden w-[32px] h-[32px]">
                    {getLeaderboardPosition(position) === 1 && (
                      <img src="/images/medal-first.webp" alt="First place medal" />
                    )}
                    {getLeaderboardPosition(position) === 2 && (
                      <img src="/images/medal-second.webp" alt="Second place medal" />
                    )}
                    {getLeaderboardPosition(position) === 3 && (
                      <img src="/images/medal-third.webp" alt="Third place medal" />
                    )}
                  </div>
                  <div className="hidden rounded-[4px] md:flex items-center justify-center bg-tower-defense-gray-default w-[32px] h-[32px] md:w-[40px] md:h-[40px]">
                    <span className="text-white font-bold text-[18px] leading-[24px]">{position}</span>
                  </div>
                </>
              ) : (
                <div className="rounded-[4px] flex items-center justify-center bg-tower-defense-gray-default w-[32px] h-[32px] md:w-[40px] md:h-[40px]">
                  <span className="text-white font-bold text-[18px] leading-[24px]">{position}</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center min-w-[160px] md:min-w-fit md:w-[250px]">
            <div className="w-[48px] min-w-[48px] h-[52px] rounded-[8px] mr-12 jungle-box-shadow-inset-blue">
              <img src={avatar_url ?? ''} alt="" className="w-[48px] h-[48px] rounded-[8px]" />
            </div>
            <div>
              <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold ">{name}</p>
              {wallet_address !== '' && (
                <CopyableAddress
                  address={wallet_address}
                  alias={wallet_alias}
                  successMessageClasses="hidden md:inline text-white"
                  textClasses="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-normal text-white"
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'grid',
            `grid-cols-${gridCols}`,
            'mt-12 xs:gap-x-8 xs:gap-y-4 xs:mt-0 xs:ml-8 xs:min-w-[240px] md:min-w-fit md:w-[240px] md:ml-16'
          )}
        >
          {showStreakData ? (
            <>
              <div className="md:w-[120px] lg:w-[140px]">
                <p className="md:hidden uppercase text-[10px] font-bold ">
                  <Trans>Longest streak</Trans>
                </p>
                <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold md:text-center">
                  {longest_streak !== undefined && formatNumber(longest_streak)}
                </p>
              </div>
              <div className="md:w-[120px] lg:w-[140px]">
                <p className="md:hidden uppercase text-[10px] font-bold ">
                  <Trans>Current streak</Trans>
                </p>
                <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold md:text-center">
                  {current_streak !== undefined && formatNumber(current_streak)}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="md:w-[48px] lg:w-[80px]">
                <p className="md:hidden uppercase text-[10px] font-bold ">
                  <Trans>Wins</Trans>
                </p>
                <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold ">
                  {wins !== undefined && formatNumber(wins)}
                </p>
              </div>
              {draws !== null ? (
                <div className="md:w-[48px] lg:w-[80px]">
                  <p className="md:hidden uppercase text-[10px] font-bold ">
                    <Trans>Draws</Trans>
                  </p>
                  <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold ">
                    {draws !== undefined && formatNumber(draws)}
                  </p>
                </div>
              ) : null}
              <div className="md:w-[48px] lg:w-[80px]">
                <p className="md:hidden uppercase text-[10px] font-bold ">
                  <Trans>Losses</Trans>
                </p>
                <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold ">
                  {losses !== undefined && formatNumber(losses)}
                </p>
              </div>
              <div className="md:w-[48px] lg:w-[80px]">
                <p className="md:hidden uppercase text-[10px] font-bold ">
                  <Trans>Points</Trans>
                </p>
                <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold ">
                  {score !== undefined && formatNumber(score)}
                </p>
              </div>
            </>
          )}

          {showRewardsBadge && (
            <div className="md:w-[48px] lg:w-[80px]">
              <p className="md:hidden uppercase text-[10px] font-bold ">
                <Trans>Rewards</Trans>
              </p>
              <Tooltip text={getTarochiBadgeTooltipText(position)} sizeVariant="small">
                <div className="flex items-center justify-start pt-2">
                  <img className="w-[28px] h-[28px]" src="/images/icon-tarochi-gold.webp" />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default LeaderboardEntry;
